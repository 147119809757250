import { useProgress, SpriteAnimator } from "@react-three/drei";
export default function Loading() {
  const { progress } = useProgress();
  return (
    <>
      <SpriteAnimator
        scale={0.5}
        position={[0, 0, 0]}
        offset={progress / 100}
        startFrame={0}
        autoPlay={true}
        pause={true}
        loop={false}
        alphaTest={0.001}
        asSprite={false}
        textureImageURL={"./images/loading.png"}
        textureDataURL={"./images/loading.json"}
      />
    </>
  );
}


  import {  useRef } from 'react'
  // import * as THREE from 'three'
  // import { useControls } from "leva";

import {
    // Plane,
    // Html,
    // OrbitControls,
    // PresentationControls,
    // Bounds,
    // Image,
    // Float,
    // useGLTF,
    // Billboard,
    // Text,
    // AccumulativeShadows,
    // RandomizedLight,
    // Environment,
    useVideoTexture,
    // SoftShadows,useHelper
    
  } from "@react-three/drei"
export default function VideoBackground(props) {


    const light = useRef()
   // useHelper(light, THREE.SpotLightHelper, 'cyan')
    const texture = useVideoTexture("/videos/caustics.mp4");
    return <spotLight  muted={true} unsuspend={'loadedmetadata'} ref={light} decay={0} map={texture} castShadow {...props} />;
}
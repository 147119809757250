import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
//  import { Perf } from 'r3f-perf'
import TitleScene from './scenes/TitleScene.jsx'
import { StrictMode ,Suspense  } from 'react'
import Loading from './components/Loading.jsx'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
  <StrictMode>
    <Canvas
      className="r3f"
      camera={{
        fov: 45,
        near: 0.1,
        far: 2000,
        position: [0, 0, 4],
      }}
      dpr={[1, 1.5]}
    >
      <Suspense fallback={<Loading />}>
        <TitleScene />
      </Suspense>
      {/* <Perf position="top-left" />  */}
    </Canvas>
  </StrictMode>
);
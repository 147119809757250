// import { Suspense } from 'react'
import {
  // Plane,
  // Html,
  // OrbitControls,
  PresentationControls,
  Bounds,
  // Image,
  Float,
  useGLTF,
  Billboard,
  // Text,
  // AccumulativeShadows,
  // RandomizedLight,
  // Environment,
  // useVideoTexture,
  // SoftShadows,
  // Sparkles,
  // Backdrop,
  // ContactShadows,
  // useHelper, 
   useTexture,
  //useKTX2,
  // MeshWobbleMaterial,
  MeshDistortMaterial,
  // Clouds,
  // Cloud
  
} from "@react-three/drei";

// import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader'

// const _KTX2Loader  = new KTX2Loader()

// import {  useRef } from 'react'
// import * as THREE from 'three'
// import { useControls } from "leva";
// import Loading from '../components/Loading.jsx'
// import Postprocessing  from '../components/Postprocessing.jsx'
import VideoBackground  from '../components/VideoBackground.jsx'



export default function TitleScene() {


  // const light2 = useRef()
  // useHelper(light2, THREE.SpotLightHelper, 'cyan')

  // const { position,angle,distance } = useControls({
  //   position: {
  //       value: { x: 1, y: 7, z: 4 },
  //       step: 0.01,
  //     },

  //    angle: {
  //       value: 0.5,
  //       step: 0.01,
  //     },
  //     distance: {
  //       value:1000,
  //       step: 1,
  //     },
  //   });


  // const  helmet  = useGLTF("../models/FlightHelmet/glTF/FlightHelmet.gltf");
  const { nodes } = useGLTF("../models/TitleScene.glb");
  const bakedTexture = useTexture('../models/TitleScene.png')
  bakedTexture.flipY = false

  const startGame = (event) => {
    console.log("startGame");
  };

  return (
    <>

{/* <Suspense fallback={<Loading />}>  */}
      {/* <group dispose={null}>   */}

  


         <color args={["#000000"]} attach="background" /> 
   
         <ambientLight intensity={1} />
         {/* <Billboard
  follow={true}
  lockX={true}
  lockY={true}
  lockZ={true} 
>
<fog attach="fog" color="#FF0000" near={2} far={10} />  
</Billboard>
      
       */}

 {/* <OrbitControls makeDefault />  */}
        <PresentationControls
        makeDefault
        snap
        global
        zoom={0.8}
        cursor={false}
        rotation={[0, 0, 0]}
        polar={[0, Math.PI / 40]}
        azimuth={[-Math.PI / 40, Math.PI / 40]}>  
    

       

     



      
       <VideoBackground distance={10} intensity={15} angle={0.5} penumbra={1} position={[0,0,6]} />   
 

  
    
 

   
   

  
       <mesh geometry={ nodes.background.geometry }>
                <meshStandardMaterial map={ bakedTexture } />
            </mesh> 




         <Bounds fit clip observe margin={1.2}>  
         {/* <primitive object={ helmet.scene } visible ={false} /> */}
         {/* <Clouds material={THREE.MeshBasicMaterial}>
  <Cloud segments={40} bounds={[10, 2, 2]} volume={10} color="orange" />
  <Cloud seed={1} scale={2} volume={5} color="hotpink" fade={100} />
</Clouds> */}

       {/* <mesh geometry={ nodes.wobble.geometry }>
    
       <MeshDistortMaterial distort={0.5} speed={0.5} map={ bakedTexture } y/>
            </mesh>  */}



{/* 
             <Float speed={1} rotationIntensity={1} floatIntensity={1} floatingRange={[-0.3, -0.3]} > */}
             

                    <mesh geometry={ nodes.boenk.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
                
            </mesh> 


            <mesh geometry={ nodes.pixel.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
                
            </mesh> 



            <mesh geometry={ nodes.pandemonium.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
                
            </mesh> 


            {/* </Float> */}

         
              {/* <Float speed={1} rotationIntensity={0} floatIntensity={1} floatingRange={[-0.3, -0.3]} > */}

              {/* <mesh geometry={ nodes.y.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
            </mesh>  */}

              {/* </Float> */}
            
              {/* <Float speed={1} rotationIntensity={0} floatIntensity={1} floatingRange={[-0.3, -0.3]} > */}
              {/* <mesh geometry={ nodes.s.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
            </mesh>  */}
              {/* </Float> */}
           
{/* 
              <Float speed={1} rotationIntensity={0} floatIntensity={1} floatingRange={[-0.3, -0.3]} > */}
              {/* <mesh geometry={ nodes.p.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
            </mesh>  */}
              {/* </Float> */}
           
              {/* <Float speed={1} rotationIntensity={0} floatIntensity={1} floatingRange={[-0.3, -0.3]} > */}
              {/* <mesh geometry={ nodes.n.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
            </mesh>  */}
              {/* </Float> */}
          
              {/* <Float speed={1} rotationIntensity={0} floatIntensity={1} floatingRange={[-0.3, -0.3]} > */}
              {/* <mesh geometry={ nodes.e.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
            </mesh>  */}
              {/* </Float> */}
           
              {/* <Float speed={1} rotationIntensity={0} floatIntensity={1} floatingRange={[-0.3, -0.3]} > */}
              {/* <mesh geometry={ nodes.a.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
            </mesh>  */}
              {/* </Float>  */}
         

         
              {/* <mesh geometry={ nodes.starving.geometry }>
                <meshStandardMaterial transparent map={ bakedTexture } />
            </mesh> 
     */}
        

            {/* <Billboard
              follow={true}
              lockX={false}
              lockY={false}
              lockZ={false} 
            > 

<mesh geometry={ nodes.startbutton.geometry }


onClick={startGame}
onPointerEnter={() => {
  document.body.style.cursor = "pointer";
}}
onPointerLeave={() => {
  document.body.style.cursor = "default";
}}

>
                <meshStandardMaterial transparent map={ bakedTexture }
                
                
           
                
                />
            </mesh> 






             </Billboard>  */}
            </Bounds>   
     

    
   
        {/* <Postprocessing />    */}
  
  
    </PresentationControls>  

     {/* </group>  */}
  {/*  </Suspense>   */}

    
    </>
  );
}
